import { useNavigate, useParams } from "react-router";
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import axios from "axios";
import Jumbotron from "../Jumbotron";

const BookEdit = ()=>{
    //파라미터 추출
    const {bookId} = useParams();

    //네비게이터
    const navigate = useNavigate();

    //state
    const [book, setBook] = useState(null);

    //effect
    //- effect에는 async를 쓸 수 없다
    useEffect(()=>{
        loadBook();
    }, []);

    //callback
    const loadBook = useCallback(async ()=>{
        try {
            //플랜 A
            const resp = await axios.get("/book/"+bookId);
            setBook(resp.data);
        }
        catch(e) {
            //플랜 B(A에서 조회가 안될 때 - 404가 반환될 때)
            setBook(null);
        }
    }, [book, bookId]);

    const changeBook = useCallback(e=>{
        setBook({
            ...book,
            [e.target.name] : e.target.value
        });
    }, [book]);

    const updateBook = useCallback(async ()=>{
        //추가한다면 변경내용이 없을 때 차단

        await axios.put("/book/", book);
        //알림 표시
        //이동 처리
        //navigate("/book/list");//목록
        navigate("/book/detail/"+bookId);//상세
    }, [book]);

    //view
    return (book !== null ? (<>
        <Jumbotron title={book.bookId+"번 도서 정보 수정"}/>

        <div className="row mt-4">
            <div className="col">
                <label>도서제목</label>
                <input type="text" name="bookTitle" className="form-control"
                    value={book.bookTitle} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>저자</label>
                <input type="text" name="bookAuthor" className="form-control"
                    value={book.bookAuthor} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>출판사</label>
                <input type="text" name="bookPublisher" className="form-control"
                    value={book.bookPublisher} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>출간일</label>
                <input type="date" name="bookPublicationDate" className="form-control"
                    value={book.bookPublicationDate} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>판매가</label>
                <input type="text" name="bookPrice" className="form-control"
                    value={book.bookPrice} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>페이지 수</label>
                <input type="text" name="bookPageCount" className="form-control"
                    value={book.bookPageCount} onChange={changeBook} />
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>장르</label>
                <select name="bookGenre" className="form-select"
                    value={book.bookGenre} onChange={changeBook}>
                    <option value="">선택하세요</option>
                    <option>판타지</option>
                    <option>소설</option>
                    <option>역사</option>
                    <option>과학</option>
                    <option>교양</option>
                </select>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col text-center">
                <button type="button" className="btn btn-lg btn-success"
                        onClick={updateBook}>수정</button>
                <button type="button" className="btn btn-lg btn-secondary ms-2"
                        onClick={e=>navigate("/book/list")}>목록</button> 
            </div>
        </div>
    </>) : (<></>));
};

export default BookEdit;