import { useCallback, useEffect, useMemo, useState } from "react";
import Jumbotron from "../Jumbotron";
import axios from "axios";
import { useParams } from "react-router";

const PayTest02Detail = ()=>{
    //params
    const {paymentNo} = useParams();

    //state
    const [info, setInfo] = useState(null);

    //effect
    useEffect(()=>{
        loadPaymentInfo();
    }, []);

    //callback
    const loadPaymentInfo = useCallback(async ()=>{
        const resp = await axios.get("/kakaopay/detail/"+paymentNo);
        setInfo(resp.data);
    }, []);

    const cancelPaymentAll = useCallback(async ()=>{
        const resp = await axios.delete("/kakaopay/cancelAll/"+paymentNo);
        //console.log(resp.data);
        loadPaymentInfo();//화면 갱신
    }, []);

    const cancelPaymentItem = useCallback(async (detail)=>{
        const resp = await axios.delete(
            "/kakaopay/cancelItem/"+detail.paymentDetailNo);
        loadPaymentInfo();//화면 갱신 처리
    }, [info]);

    if(info === null) {
        return <h1>데이터를 불러오고 있습니다</h1>
    }

    //view
    return (<>
        <Jumbotron title="결제 상세 정보 보기"/>

        <div className="row mt-4">
            <div className="col">
                
                <h2>결제 요약 정보</h2>

                <div className="row mt-2">
                    <div className="col-3">결제상품명</div>
                    <div className="col-3">{info.paymentDto.paymentName}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">총 결제금액</div>
                    <div className="col-3">{info.paymentDto.paymentTotal}원</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-danger"
                            disabled={info.paymentDto.paymentRemain <= 0}
                            onClick={cancelPaymentAll}>
                                전체취소
                        </button>
                    </div>
                </div>

                <h2 className="mt-4">결제 상세 항목</h2>

                <ul className="list-group mt-2">
                {info.paymentDetailList.map(detail=>(
                    <li className="list-group-item">
                        <h4>{detail.paymentDetailName}</h4>
                        <div className="row mt-2">
                            <div className="col-3">판매가</div>
                            <div className="col-9">{detail.paymentDetailPrice}원</div>
                        </div>                        
                        <div className="row mt-2">
                            <div className="col-3">구매수량</div>
                            <div className="col-9">{detail.paymentDetailQty}</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-3">소계</div>
                            <div className="col-9">{detail.paymentDetailPrice * detail.paymentDetailQty}원</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-3">상태</div>
                            <div className="col-9">{detail.paymentDetailStatus}</div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <button className="btn btn-danger"
                                        disabled={detail.paymentDetailStatus === '취소'}
                                        onClick={e=>cancelPaymentItem(detail)}>
                                    항목취소
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
                </ul>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col">

                <h2>카카오페이 정보</h2>
                
                <div className="row mt-2">
                    <div className="col-3">거래번호</div>
                    <div className="col-9">{info.responseVO.tid}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">거래상태</div>
                    <div className="col-9">{info.responseVO.status}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">내부 거래번호</div>
                    <div className="col-9">{info.responseVO.partner_order_id}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">구매자ID</div>
                    <div className="col-9">{info.responseVO.partner_user_id}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">구매방식</div>
                    <div className="col-9">{info.responseVO.payment_method_type}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">구매금액</div>
                    <div className="col-9">
                        {info.responseVO.amount.total}원
                        (부가세 포함, 부가세 {info.responseVO.amount.vat}원)
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">취소완료금액</div>
                    <div className="col-9">
                        {info.responseVO.canceled_amount.total}원
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">취소가능금액</div>
                    <div className="col-9">
                        {info.responseVO.cancel_available_amount.total}원
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">상품명</div>
                    <div className="col-9">{info.responseVO.item_name}</div>
                </div>
                {info.responseVO.item_code !== null && (
                <div className="row mt-2">
                    <div className="col-3">상품코드</div>
                    <div className="col-9">{info.responseVO.item_code}</div>
                </div>
                )}
                <div className="row mt-2">
                    <div className="col-3">결제시작일시</div>
                    <div className="col-9">{info.responseVO.created_at}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-3">결제완료일시</div>
                    <div className="col-9">{info.responseVO.approved_at}</div>
                </div>
                {info.responseVO.canceled_at !== null && (
                <div className="row mt-2">
                    <div className="col-3">결제취소일시</div>
                    <div className="col-9">{info.responseVO.canceled_at}</div>
                </div>
                )}

                {/* 결제 상세 내역 */}
                <ul className="list-group mt-4">
                {info.responseVO.payment_action_details.map((action, index)=>(
                    <li className="list-group-item" key={index}>
                        <div className="row">
                            <div className="col-3">요청번호</div>
                            <div className="col-9">{action.aid}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">요청유형</div>
                            <div className="col-9">{action.payment_action_type}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">요청금액</div>
                            <div className="col-9">{action.amount}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">요청승인일시</div>
                            <div className="col-9">{action.approved_at}</div>
                        </div>
                        {action.payload !== null && (
                        <div className="row">
                            <div className="col-3">추가요청사항</div>
                            <div className="col-9">{action.payload}</div>
                        </div>
                        )}
                    </li>
                ))}
                </ul>
            </div>
        </div>

    </>);
};

export default PayTest02Detail;