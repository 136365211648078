import { useCallback, useEffect, useMemo, useState } from "react";
import Jumbotron from "../Jumbotron";
import axios from "axios";


const PayTest02 = ()=>{
    //state
    const [bookList, setBookList] = useState([]);

    //effect
    useEffect(()=>{
        loadBookList();
    }, []);

    //callback
    const loadBookList = useCallback(async ()=>{
        const resp = await axios.get("/book/");
        //setBookList(resp.data);
        setBookList(resp.data.map(book=>{
            return {
                ...book, 
                select:false,//체크박스용 상태값 추가
                qty:1,//수량 선택용 상태값 추가
            }
        }));
    }, []);

    const selectBook = useCallback((target, checked)=>{
        setBookList(bookList.map(book=>{
            if(book.bookId === target.bookId) {//내가 찾는 책이면 select를 변화
                return {...book, select:checked};
            }
            return {...book};//아니면 그대로 반환
        }));
    }, [bookList]);

    const changeBookQty = useCallback((target, qty)=>{
        setBookList(bookList.map(book=>{
            if(book.bookId === target.bookId) {
                return {...book, qty: qty};
            }
            return {...book};
        }));
    }, [bookList]);

    //memo - 체크된 도서 목록, 총 결제 예상 금액
    const checkedBookList = useMemo(()=>{
        return bookList.filter(book=>book.select === true);
    }, [bookList]);

    const checkedBookTotal = useMemo(()=>{
        return checkedBookList.reduce((before, current)=>{
            //return 누적합계 + (현재도서가격 * 현재도서수량)
            return before + (current.bookPrice * current.qty);
        }, 0);
    }, [checkedBookList]);

    const getCurrentUrl = useCallback(()=>{
        return window.location.origin 
                    + window.location.pathname 
                    + (window.location.hash || '');
    }, []);

    const sendPurchaseRequest = useCallback(async ()=>{
        if(checkedBookList.length === 0) return;

        const resp = await axios.post(
                    "/book/purchase", 
                    {
                        bookList : checkedBookList ,
                        approvalUrl : getCurrentUrl() + "/success",
                        cancelUrl : getCurrentUrl() + "/cancel",
                        failUrl : getCurrentUrl() + "/fail",
                    }
        );
        
        //console.log(resp.data);

        //결제 페이지 이동 전에 필요한 항목들을 모두 sessionStorage에 저장
        //[1] 거래번호(tid)
        //[2] 구매한 도서 목록(checkedBookList)
        window.sessionStorage.setItem("tid", resp.data.tid);
        //window.sessionStorage.setItem("checkedBookList", checkedBookList);//원하는 결과로 저장이 안됨
        window.sessionStorage.setItem("checkedBookList", JSON.stringify(checkedBookList));//JSON으로 변환하여 저장

        //결제 페이지로 이동
        window.location.href = resp.data.next_redirect_pc_url;
    }, [checkedBookList]);

    //view
    return (<>
        <Jumbotron title="도서 결제 페이지"/>

        <div className="row mt-4">
            <div className="col">
                
                <table className="table">
                    <thead>
                        <tr>
                            <th>선택</th>
                            <th>번호</th>
                            <th>제목</th>
                            <th>저자</th>
                            <th>출판사</th>
                            <th>가격</th>
                            <th>수량</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookList.map(book=>(
                        <tr key={book.bookId}>
                            <td>
                                <input type="checkbox" className="form-check-input"
                                    checked={book.select}
                                    onChange={e=>selectBook(book, e.target.checked)}/>
                            </td>    
                            <td>{book.bookId}</td>
                            <td>{book.bookTitle}</td>
                            <td>{book.bookAuthor}</td>
                            <td>{book.bookPublisher}</td>
                            <td>{book.bookPrice}원</td>
                            <td>
                                <input type="number" className="form-control"
                                        min="1" step="1"
                                        //style="width:100px"
                                        style={{width:"100px"}}
                                        value={book.qty}
                                        onChange={e=>changeBookQty(book, e.target.value)}
                                        />
                            </td>
                        </tr> 
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col">
                <h2>결제하실 금액은 {checkedBookTotal}원 입니다</h2>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col">
                <button className="btn btn-success w-100"
                        onClick={sendPurchaseRequest}>
                    구매하기
                </button>
            </div>
        </div>
    </>);
};

export default PayTest02;