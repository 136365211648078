import { useCallback, useEffect, useState } from "react";
import Jumbotron from "../Jumbotron";
import axios from "axios";
import { NavLink } from "react-router-dom";

const PayTest02List = ()=>{
    //state
    const [paymentList, setPaymentList] = useState([]);

    //effect
    useEffect(()=>{
        loadPaymentList();
    }, []);

    //callback
    const loadPaymentList = useCallback(async ()=>{
        const resp = await axios.get("/book/paymentlist");
        setPaymentList(resp.data);
    }, []);

    const loadPaymentDetailList = useCallback(async (target)=>{
        const resp = await axios.get(
            "/book/paymentlist/"+target.paymentNo);

        setPaymentList(paymentList.map(payment=>{
            if(payment.paymentNo === target.paymentNo) {//클릭한 항목
                return {
                    ...payment,//나머지 정보 유지
                    paymentDetailList : resp.data //불러온 데이터를 항목에 추가
                }
            }
            return {...payment};//나머지는 정보 유지
        }));
    }, [paymentList]);

    //view
    return (<>
        <Jumbotron title="결제 내역 조회"/>

        <div className="row mt-4">
            <div className="col">

                <ul className="list-group">
                    {paymentList.map(payment=>(
                    <li key={payment.paymentNo} className="list-group-item"
                                onClick={e=>loadPaymentDetailList(payment)}>
                        <h3 className="d-flex justify-content-between">
                            {payment.paymentName}
                            <small className="ms-2 text-muted">
                                {payment.paymentTotal}원
                            </small>
                        </h3>

                        {/* 상세 결제 내용이 불러와질 자리 */}
                        {payment.paymentDetailList?.length > 0 && (
                        <ul className="list-group list-group-flush mt-4">
                            {payment.paymentDetailList.map(detail=>(
                            <li className="list-group-item" key={detail.paymentDetailNo}>
                                <h3 className="d-flex justify-content-between">
                                    {detail.paymentDetailName}
                                    <small className="ms-2 text-muted">
                                        {detail.paymentDetailPrice}원
                                        x
                                        {detail.paymentDetailQty}권
                                        =
                                        {detail.paymentDetailPrice * detail.paymentDetailQty}원
                                    </small>
                                </h3>
                            </li>
                            ))}
                        </ul>
                        )}

                        {/* 더 많은 정보가 궁금할 경우 카카오페이 호출 필요 */}
                        <div className="text-end">
                            <NavLink to={"/paytest02/detail/"+payment.paymentNo}>더보기</NavLink>
                            <NavLink to={`/paytest02/detail/${payment.paymentNo}`}>더보기</NavLink>
                        </div>
                    </li>
                    ))}
                </ul>

            </div>
        </div>
    </>);
};

export default PayTest02List;