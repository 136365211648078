import Jumbotron from "../Jumbotron";
import { PiTildeBold } from "react-icons/pi";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useState, useRef } from 'react';
import { useCallback } from 'react';
import axios from "axios";
import { useMemo } from 'react';
import { useEffect } from 'react';
import { FaChevronDown } from "react-icons/fa";
import { throttle } from "lodash";

const MemberComplexSearch = ()=>{
    //state
    //- 내부적으로 사용할 데이터와 백엔드로 전송할 데이터를 구분
    //- 백엔드에서 요구하는 항목과 같은 이름으로 만들면 편하다
    const [input, setInput] = useState({
        memberId : "",
        memberNickname:"",
        memberBirth:"",
        memberContact:"",
        memberEmail:"",
        memberLevelList:[],
        minMemberPoint:"",
        maxMemberPoint:"",
        memberAddress:"",
        beginMemberJoin:"",
        endMemberJoin:"",
        beginMemberLogin:"",
        endMemberLogin:"",
    });

    const [result, setResult] = useState({
        count:0,
        last:true,
        memberList:[]
    });

    //페이징 관련 state
    const [page, setPage] = useState(null);
    const [size, setSize] = useState(10);

    // [1] memo로 계산 (좋은 방법은 아니지만 이게 되네?)
    // input.beginRow = useMemo(()=>{
    //     return page * size - (size-1);
    // }, [page, size]);
    // input.endRow = useMemo(()=>{
    //     return page * size;
    // }, [page, size]);

    // [2] effect로 계산 (권장하는 방법!)
    useEffect(()=>{
        setInput({
            ...input,
            beginRow : page * size - (size-1),
            endRow : page * size
        });
    }, [page, size]);

    useEffect(()=>{
        if(page === null) return;//초기상태

        //console.log("beginRow, endRow 변경! ", input.beginRow, input.endRow);
        if(page === 1) {
            sendRequest();
        }
        else if(page >= 2) {
            sendMoreRequest();        
        }
    }, [input.beginRow, input.endRow]);

    //callback
    const changeInputString = useCallback(e=>{
        setInput({
            ...input, 
            [e.target.name] : e.target.value
        });
    }, [input]);
    const changeInputNumber = useCallback(e=>{
        setInput({
            ...input, 
            [e.target.name] : parseInt(e.target.value) || ""
        });
    }, [input]);
    const changeInputArray = useCallback(e=>{
        //console.log(e.target.name, e.target.value, e.target.checked);
        const origin = input[e.target.name];//input의 항목을 하나 꺼낸다

        if(e.target.checked === true) {//추가
            setInput({
                ...input,
                [e.target.name] : origin.concat(e.target.value)
            });
        }
        else {//삭제
            setInput({
                ...input,
                [e.target.name] : origin.filter(level=>level !== e.target.value)
            });
        }
    }, [input]);

    //첫 목록 불러올 때 사용
    const sendRequest = useCallback(async ()=>{
        loading.current = true;//시작지점
        const resp = await axios.post("/member/search", input);
        //console.log(resp.data);
        setResult(resp.data);
        loading.current = false;//종료지점
    }, [input]);
    //더보기 눌렀을 때 사용
    const sendMoreRequest = useCallback(async ()=>{
        loading.current = true;//시작지점
        const resp = await axios.post("/member/search", input);
        //setResult(resp.data);//덮어쓰기라 안됨!
        setResult({
            ...result,
            last: resp.data.last,//서버에서 준 응답 데이터에 있는 last로 갱신
            //memberList: result.memberList.concat(resp.data.memberList)
            memberList: [...result.memberList, ...resp.data.memberList]
        });
        loading.current = false;//종료지점
    }, [input.beginRow, input.endRow]);    

    //지금까지의 구조가 99% 만족스럽지만 1%의 아쉬움이 있다
    //- effect는 같은 값으로 변경될 때 인지하지 못한다
    //- 어떤 항목을 연속적으로 검색하면 계속 1페이지여서 반영이 안된다
    //- 시간차를 두고 1--->1이 아니라 1--->null--->1이 되도록 구현
    //- JS의 setTimeout(함수, 시간)을 이용하여 약간의 시간차를 부여
    const setFirstPage = useCallback(()=>{

        //아래 명령은 어느게 먼저 실행된다고 장담할 수 없다(비동기)
        //setPage(null);
        //setPage(1);

        //useState에서 제공하는 set 메소드에 동기로 처리할 수 있는 코드가 존재
        //setPage(prev=>?);
        setPage(prev=>null);
        setTimeout(()=>{
            setPage(prev=>1);
        }, 1);//이 코드는 1ms 뒤에 실행해라!
        
    }, [page]);

    //스크롤 관련된 처리
    //- useEffect(함수) - 화면이 변경될 때마다 실행됨
    //- useEffect에서 함수를 반환하면 화면이 사라질 때 실행할 작업 지정 가능
    useEffect(()=>{
        if(page === null) return;//결과를 검색하지 않았을 때
        if(result.last === true) return;//더 볼게 없을 때

        //리사이즈에 사용할 함수
        const resizeHandler = throttle(()=>{
            const percent = getScrollPercent();
            console.log("percent = " + percent);
            if(percent >= 70 && loading.current === false) {
                console.log("더보기 실행하세요!");
                setPage(page+1);
            }
        }, 250);

        //윈도우에 resize 이벤트를 설정
        window.addEventListener("scroll", resizeHandler);

        return ()=>{
            //윈도우에 설정된 resize 이벤트를 해제
            window.removeEventListener("scroll", resizeHandler);
        };
    });

    //- 스크롤의 현재 위치를 퍼센트로 계산하는 함수(Feat.ChatGPT)
    const getScrollPercent = useCallback(()=>{
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = (scrollTop / documentHeight) * 100;
        return scrollPercent;
    });

    //(중요) 로딩중에 추가로딩이 불가능하게 처리하기 위한 REF 사용
    //- 목록을 불러오기 시작하면 loading.current = true;로 변경
    //- 목록을 불러오고 나면 loading.current = false;로 변경
    const loading = useRef(false);

    //view
    return (<>
        <Jumbotron title="회원 상세정보 검색"/>

        {/* 검색 화면 */}
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">아이디</label>
            <div className="col-sm-9">
                <input type="text" className="form-control"
                    name="memberId" value={input.memberId} 
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">닉네임</label>
            <div className="col-sm-9">
                <input type="text" className="form-control"
                    name="memberNickname" value={input.memberNickname}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">생년월일</label>
            <div className="col-sm-9">
                <input type="date" className="form-control"
                    name="memberBirth" value={input.memberBirth}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">연락처</label>
            <div className="col-sm-9">
                <input type="tel" className="form-control"
                    name="memberContact" value={input.memberContact}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">이메일</label>
            <div className="col-sm-9">
                <input type="email" className="form-control"
                    name="memberEmail" value={input.memberEmail}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">등급</label>
            <div className="col-sm-9">
                <label>
                    <input type="checkbox" className="form-check-input"
                        name="memberLevelList" value="일반회원"
                        onChange={changeInputArray}/>
                    <span className="ms-1">일반회원</span>
                </label>
                <label className="ms-4">
                    <input type="checkbox" className="form-check-input"
                        name="memberLevelList" value="우수회원"
                        onChange={changeInputArray}/>
                    <span className="ms-1">우수회원</span>
                </label>
                <label className="ms-4">
                    <input type="checkbox" className="form-check-input"
                        name="memberLevelList" value="관리자"
                        onChange={changeInputArray}/>
                    <span className="ms-1">관리자</span>
                </label>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">포인트</label>
            <div className="col-sm-9 d-flex">
                <input type="text" className="form-control"
                    name="minMemberPoint" value={input.minMemberPoint}
                    onChange={changeInputNumber}/>
                <span className="mx-2">
                    <PiTildeBold/>
                </span>
                <input type="text" className="form-control"
                    name="maxMemberPoint" value={input.maxMemberPoint}
                    onChange={changeInputNumber}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">주소</label>
            <div className="col-sm-9">
                <input type="text" className="form-control"
                    name="memberAddress" value={input.memberAddress}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">가입일</label>
            <div className="col-sm-9 d-flex">
                <input type="date" className="form-control"
                    name="beginMemberJoin" value={input.beginMemberJoin}
                    onChange={changeInputString}/>
                <span className="mx-2">
                    <PiTildeBold/>
                </span>
                <input type="date" className="form-control"
                    name="endMemberJoin" value={input.endMemberJoin}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">최종로그인</label>
            <div className="col-sm-9 d-flex">
                <input type="date" className="form-control"
                    name="beginMemberLogin" value={input.beginMemberLogin}
                    onChange={changeInputString}/>
                <span className="mx-2">
                    <PiTildeBold/>
                </span>
                <input type="date" className="form-control"
                    name="endMemberLogin" value={input.endMemberLogin}
                    onChange={changeInputString}/>
            </div>
        </div>
        <div className="row mt-4">
            <label className="col-sm-3 col-form-label">정렬방식</label>
            <div className="col-sm-9">
                
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <button className="btn btn-success w-100"
                            onClick={setFirstPage}>
                    <FaMagnifyingGlass/>
                    <span className="ms-2">이 조건으로 상세 검색하기</span>
                </button>
            </div>
        </div>

        {/* 결과 표시 화면 */}
        <div className="row mt-4">
            <div className="col">
                <ul className="list-group">

                    {result.memberList.map(member=>(
                    <li className="list-group-item" key={member.memberId}>
                        <h3>{member.memberId}</h3>
                        <div className="row">
                            <div className="col-3">닉네임</div>
                            <div className="col-9">{member.memberNickname}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">이메일</div>
                            <div className="col-9">{member.memberEmail}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">생년월일</div>
                            <div className="col-9">{member.memberBirth}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">연락처</div>
                            <div className="col-9">{member.memberContact}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">등급</div>
                            <div className="col-9">{member.memberLevel}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">포인트</div>
                            <div className="col-9">{member.memberPoint} p</div>
                        </div>
                        <div className="row">
                            <div className="col-3">주소</div>
                            <div className="col-9">
                                {member.memberPost !== null && (<>
                                    [{member.memberPost}]
                                    {member.memberAddress1}
                                    {member.memberAddress2}
                                </>)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">가입일</div>
                            <div className="col-9">{member.memberJoin}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">최종로그인</div>
                            <div className="col-9">{member.memberLogin}</div>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
        </div>

        {/* 더보기 버튼 - result의 last가 false이면(더 볼게 있다면) */}
        {/* A ? B : C */}
        {/* A && B */}
        {/* A || C */}
        {result.last === false && (
        <div className="row mt-4">
            <div className="col">
                <button className="btn btn-success w-100"
                            onClick={e=>setPage(page+1)}>
                    <FaChevronDown/>
                    <span className="mx-4">더보기</span>
                    <FaChevronDown/>
                </button>
            </div>
        </div>
        )}
    </>);
};

export default MemberComplexSearch;