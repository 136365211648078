import { useRecoilState } from "recoil";
import { countState } from "../../utils/recoil";

const DataExam02 = (props)=>{
    const [recoilCount, setRecoilCount] = useRecoilState(countState);

    return (<>
        <button onClick={e=>props.setCount(props.count+1)}>+1(state)</button>
        <button onClick={e=>setRecoilCount(recoilCount+1)}>+1(recoil state)</button>
    </>);
};

export default DataExam02;