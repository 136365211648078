import Jumbotron from "./Jumbotron";
import { HiMiniXMark } from "react-icons/hi2";
import { IoIosSave } from "react-icons/io";
import { useState } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';

const BankAccount = ()=>{
    //state
    const [input, setInput] = useState({
        type : "입금",
        amount : "",
        memo : "",
    });

    const [history, setHistory] = useState([
        //{no : 1 , type : "입금", amount : 50000, memo : "용돈"},
        //{no : 2 , type : "출금", amount : 25000, memo : "밥값"},
    ]);

    const [message, setMessage] = useState("");

    //callback
    //- 기록 삭제 함수
    const removeHistory = useCallback((target)=>{
        //삭제 확인창 출력
        const choice = window.confirm("내역을 삭제하면 복구할 수 없습니다\n정말 삭제하시겠습니까?");
        if(choice === false) return;

        setHistory(history.filter((record)=>record.no !== target.no));
    }, [history]);
    const addHistory = useCallback(()=>{
        //금액이 숫자가 아니면 차단
        if(/^[0-9]+$/.test(input.amount) === false) {
            //window.alert("금액을 숫자로 입력하세요");
            setMessage("금액을 숫자로 입력하세요");
            return;
        }
        //출금 금액이 잔액보다 크면 차단
        if(input.type === '출금' && parseInt(input.amount) > balance) {
            //window.alert("출금 금액은 잔액보다 클 수 없습니다!");
            setMessage("출금 금액은 잔액보다 클 수 없습니다!");
            return;
        }
        //출금일 경우 확인 메세지 출력
        if(input.type === '출금') {
            const choice = window.confirm("정말 출금하시겠습니까?");
            if(choice === false) return;//취소 시 차단
        }


        //(참고) ?. 은 해당 항목이 존재하지 않을 때도 코드를 실행시킴
        const newRecord = {
            ...input,
            no : history[history.length - 1] ?. no + 1 || 1,
            amount : parseInt(input.amount)
        };
        setHistory([...history, newRecord]);

        clearInput();
    }, [input, history]);
    const changeInput = useCallback(e=>{
        setInput({
            ...input,
            [e.target.name] : e.target.value
        });
    }, [input]);
    const clearInput = useCallback(()=>{
        //입금,출금은 유지하고 나머지를 초기화
        setInput({
            ...input,
            amount : "",
            memo : "",
        });
        setMessage("");
    }, [input]);

    //- GPT가 만들어준 세자리마다 콤마찍는 함수
    const formatNumberWithCommas = useCallback((num)=>{
        // 숫자가 아닌 경우, 숫자로 변환을 시도
        if (isNaN(num)) {
            return num;
        }
        
        // 정수와 소수 부분을 나누기
        const parts = num.toString().split('.');
        // 정수 부분에 3자리마다 콤마 추가
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // 소수 부분이 있으면 다시 합치기
        return parts.join('.');
    }, []);


    //memo
    const balance = useMemo(()=>{
        return history.reduce((acc, cur)=> {
            if(cur.type === "입금")
                return acc + cur.amount;
            else if(cur.type === "출금")
                return acc - cur.amount;
            return acc;
        }, 0);
    }, [history]);

    //view
    return (<>
        <Jumbotron title="통장 관리"/>

        <div className="row mt-4">
            <div className="col">
                <table className="table table-bordered table-hover">
                    <thead className="text-center">
                        <tr>
                            <th>번호</th>
                            <th>구분</th>
                            <th>금액</th>
                            <th>메모</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {history.map(record=>(
                        <tr key={record.no}>
                            <td className={record.type === '입금' ? 'text-success' : 'text-danger'}>{record.no}</td>
                            <td className={record.type === '입금' ? 'text-success' : 'text-danger'}>{record.type}</td>
                            <td className={`${record.type === '입금' ? 'text-success' : 'text-danger'} text-end`}>{formatNumberWithCommas(record.amount)}</td>
                            <td className={`${record.type === '입금' ? 'text-success' : 'text-danger'} text-start`}>{record.memo}</td>
                            <td>
                                <HiMiniXMark className="text-danger" onClick={e=>removeHistory(record)}/>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

        {/* 입력창 */}  
        <div className="row mt-2">
            <div className="col">
                <div className="d-flex">
                    <select className="form-select w-auto" name="type" value={input.type} onChange={changeInput}>
                        <option>입금</option>
                        <option>출금</option>
                    </select>
                    <input type="text" className="form-control ms-2" placeholder="금액" name="amount" value={input.amount} onChange={changeInput}/>
                    <input type="text" className="form-control ms-2" placeholder="메모" name="memo" value={input.memo} onChange={changeInput}/>
                    <button className="btn btn-success ms-2" style={{minWidth:"100px"}} onClick={addHistory}>
                        <IoIosSave className="me-1"/> 등록
                    </button>
                </div>
            </div>
        </div>
        <div className="row mt-2">
            <div className="col text-danger text-center">{message}</div>
        </div>

        {/* 잔액 */}
        <div className="row mt-4">
            <div className="col text-end">
                <h3>계좌 잔액 : {formatNumberWithCommas(balance)}원</h3>
            </div>
        </div>
    </>);
};

export default BankAccount;