import { Route, Routes } from "react-router";
import AutoComplete from "./AutoComplete";
import MemberComplexSearch from "./MemberComplexSearch";
import AutoComplete2 from "./AutoComplete2";

const Search = ()=>{

    return (<>
        <Routes>
            <Route path="/autocomplete" element={<AutoComplete/>}></Route>
            <Route path="/autocomplete2" element={<AutoComplete2/>}></Route>
            <Route path="/member" element={<MemberComplexSearch/>}></Route>
        </Routes>
    </>);
};

export default Search;