import { useEffect } from "react";
import Jumbotron from "../Jumbotron";

const PayTest02Cancel = ()=>{

    //(중요) 결제 시작 전에 담아둔 값들을 제거
    useEffect(()=>{
        window.sessionStorage.removeItem("tid");
        window.sessionStorage.removeItem("checkedBookList");
    }, []);

    return (<>
        <Jumbotron title="결제를 취소하셨습니다"/>
    </>);
};

export default PayTest02Cancel;