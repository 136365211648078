import { useRecoilState } from "recoil";
import DataExam02 from "./DataExam02";
import DataExam03 from "./DataExam03";
import { useState } from 'react';
import { countState } from "../../utils/recoil";

const DataExam01 = ()=>{
    //state
    const [count, setCount] = useState(0);

    //recoil state
    //- 변수는 state처럼 선언한다
    //- useRecoilState 안에는 실질적으로 사용할 recoil state의 이름을 적는다
    const [recoilCount, setRecoilCount] = useRecoilState(countState);

    //view
    return (<>
        <h1>카운트 : {count}</h1>
        <h1>리코일 카운트 : {recoilCount}</h1>

        <DataExam02 count={count} setCount={setCount}/>
        <DataExam03 count={count} setCount={setCount}/>
    </>);
};

export default DataExam01;