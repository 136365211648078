import { useRecoilState } from "recoil";
import { countState } from "../../utils/recoil";

const DataExam03 = ({count, setCount})=>{

    const [recoilCount, setRecoilCount] = useRecoilState(countState);

    return (<>
        <button onClick={e=>setCount(count-1)}>-1(state)</button>
        <button onClick={e=>setRecoilCount(recoilCount-1)}>-1(recoil state)</button>
    </>);
};

export default DataExam03;