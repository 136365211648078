import { useCallback, useState } from "react";
import Jumbotron from "../Jumbotron";
import axios from "axios";

//단순하게 상품명과 금액을 입력하면 결제가 이루어지도록 구현
const PayTest01 = ()=>{
    //state
    const [input, setInput] = useState({
        itemName:"",
        totalAmount:"",
        approvalUrl: window.location.href+"/success",
        cancelUrl: window.location.href+"/cancel",
        failUrl: window.location.href+"/fail"
    });

    //callback
    const changeInput = useCallback(e=>{
        setInput({
            ...input, 
            [e.target.name] : e.target.value
        });
    }, [input]);

    const sendReadyRequest = useCallback(async ()=>{
        const resp = await axios.post("/kakaopay/ready", input);
        //console.log(resp);
        window.sessionStorage.setItem("tid", resp.data.tid);
        window.location.href = resp.data.next_redirect_pc_url;
    }, [input]);

    //view
    return (<>
        <Jumbotron title="아주 무식한 결제 방법"/>

        <div className="row mt-4">
            <div className="col">
                <label>상품명</label>
                <input type="text" className="form-control" 
                    name="itemName" value={input.itemName}
                    onChange={changeInput}/>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <label>금액</label>
                <input type="text" className="form-control" 
                    name="totalAmount" value={input.totalAmount}
                    onChange={changeInput}/>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col">
                <button className="btn btn-success w-100"
                            onClick={sendReadyRequest}>
                    구매하기
                </button>
            </div>
        </div>
    </>);
};

export default PayTest01;