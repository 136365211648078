import axios from "axios";
import Jumbotron from "../Jumbotron";
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
// import lodash from "lodash";
import {throttle, debounce} from "lodash";

const AutoComplete2 = ()=>{
    //state
    const [keyword, setKeyword] = useState("");
    const [open, setOpen] = useState(false);
    const [poketmonList, setPoketmonList] = useState([]);

    //effect
    //- keyword가 변하면 그에 맞는 검색 결과를 요청하자!
    useEffect(()=>{
        console.log("effect 실행");
        searchPoketmonList(keyword);
    }, [keyword]);

    //callback
    //- keyword로 검색 결과를 요청하는 함수

    //before
    // const searchPoketmonList = useCallback(async ()=>{
    //     if(keyword.length === 0) return;
    //     const resp = await axios.get(`/poketmon/column/poketmon_name/keyword/${keyword}`);
    //     setPoketmonList(resp.data);
    // }, [keyword, poketmonList]);

    //after
    //- 원래 함수 자리에 throttle(함수, 주기)를 삽입
    //- 연관항목을 제거(항목이 변해도 쓰로틀링이 유지)
    //- 연관항목을 제거하면 외부의 값은 사용이 불가능하므로 전달한 값은 매개변수로 처리
    const searchPoketmonList = useCallback(throttle(async (keyword)=>{
        console.log("throttle 실행");
        console.log("keyword = " + keyword +"("+keyword.length+")");
        if(keyword.length === 0) return;
        const resp = await axios.get(`/poketmon/column/poketmon_name/keyword/${keyword}`);
        setPoketmonList(resp.data);
    }, 1000), []);//keyword와 poketmonList가 변해도 쓰로틀은 유지

    //view
    return (<>
        <Jumbotron title="자동완성 만들어보기"
                content="검색어가 입력될 때마다 서버로 요청 보내기"/>

        <div className="row mt-4">
            <div className="col">
                <div className="form-group">
                    {/* 입력창 */}
                    <input type="text" value={keyword} className="form-control"
                        onChange={e=>setKeyword(e.target.value)}
                        placeholder="몬스터 이름 입력"/>
                    {/* 자동완성 */}
                    <ul className="list-group">
                        {poketmonList.map(poketmon=>(
                        <li key={poketmon.poketmonNo} className="list-group-item">
                            {poketmon.poketmonName}
                        </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </>);
};

export default AutoComplete2;