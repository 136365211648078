import Jumbotron from "./Jumbotron";
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { FaTrash } from "react-icons/fa";
import { RiAddLine } from "react-icons/ri";

const FruitCart = ()=>{
    //state
    const [input, setInput] = useState({
        fruitName : "",
        fruitPrice : "",
        fruitQty : "",
    });
    const [fruitList, setFruitList] = useState([
        {fruitNo : 1 , fruitName : "샤인머스켓", fruitPrice : 3000 , fruitQty : 1},
        {fruitNo : 2 , fruitName : "제주감귤", fruitPrice : 2000 , fruitQty : 7},
    ]);

    //callback
    const changeInput = useCallback(e=>{
        setInput({
            ...input,
            [e.target.name] : e.target.value
        });
    }, [input]);
    const clearInput = useCallback(()=>{
        setInput({
            fruitName : "",
            fruitPrice : "",
            fruitQty : "",
        })
    }, [input]);
    const removeFruit = useCallback((target)=>{
        setFruitList(fruitList.filter((fruit)=>{
            return fruit.fruitNo !== target.fruitNo;//번호가 다른 과일만 수집
        }));
    }, [fruitList]);
    const addFruit = useCallback(()=>{
        if(input.fruitName.length === 0) return;//과일명 미작성시 차단
        if(input.fruitPrice.length === 0) return;//판매가 미작성시 차단
        if(input.fruitQty.length === 0) return;//수량 미작성시 차단

        const fruitNo = fruitList[fruitList.length-1]?.fruitNo+1 || 1;
        setFruitList([...fruitList, {
            fruitNo : fruitNo,
            fruitName : input.fruitName,
            fruitPrice : parseInt(input.fruitPrice),
            fruitQty : parseInt(input.fruitQty)
        }]);

        clearInput();
        
    }, [input, fruitList]);

    //GPT가 만들어준 세자리마다 콤마찍는 함수
    //function formatNumberWithCommas(num) {
    const formatNumberWithCommas = useCallback((num)=>{
        // 숫자가 아닌 경우, 숫자로 변환을 시도
        if (isNaN(num)) {
            return num;
        }
        
        // 정수와 소수 부분을 나누기
        const parts = num.toString().split('.');
        // 정수 부분에 3자리마다 콤마 추가
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // 소수 부분이 있으면 다시 합치기
        return parts.join('.');
    }, []);


    //memo
    // const total = useMemo(()=>{
    //     let sum = 0;
    //     for(let i=0; i < fruitList.length; i++) {
    //         sum += fruitList[i].fruitPrice * fruitList[i].fruitQty;
    //     }
    //     return sum;
    // }, [fruitList]);

    //ES6에서는 배열을 종합해서 뭔가를 구할 때 reduce라는 함수를 쓴다
    const total = useMemo(()=>{
        //return fruitList.reduce(함수, 초기값);
        return fruitList.reduce((sum, fruit)=>{
            return sum + (fruit.fruitPrice * fruit.fruitQty);
        }, 0);
    }, [fruitList]);

    //view
    return (<>
        <Jumbotron title="과일 구매하기" content="원하시는 과일과 단가 수량을 입력해서 금액을 계산하세요"/>

        {/* 테이블 영역 */}
        <div className="row mt-4">
            <div className="col">

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>이름</th>
                            <th>가격</th>
                            <th>수량</th>
                            <th>합계</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* fruitList의 데이터 개수만큼 반복하여 출력 */}
                        {fruitList.map((fruit, index)=>(
                        <tr key={fruit.fruitNo}>
                            <td>{fruit.fruitNo}</td>
                            <td>{fruit.fruitName}</td>
                            <td>{formatNumberWithCommas(fruit.fruitPrice)}</td>
                            <td>{formatNumberWithCommas(fruit.fruitQty)}</td>
                            <td>{formatNumberWithCommas(fruit.fruitPrice * fruit.fruitQty)}</td>
                            <td>
                                <button className="btn btn-danger"
                                    onClick={e=>removeFruit(fruit)}>
                                        <FaTrash className="me-1"/>삭제
                                </button>
                            </td>
                        </tr>
                        ))}

                        {/* 입력창 */}
                        <tr>
                            <td></td>
                            <td>
                                <input type="text" className="form-control w-auto"
                                        placeholder="과일이름"
                                        name="fruitName"
                                        value={input.fruitName}
                                        onChange={changeInput}/>
                            </td>
                            <td>
                                <input type="text" className="form-control w-auto"
                                        placeholder="가격"
                                        name="fruitPrice"
                                        value={input.fruitPrice}
                                        onChange={changeInput}/>
                            </td>
                            <td>
                                <input type="text" className="form-control w-auto"
                                        placeholder="수량"
                                        name="fruitQty"
                                        value={input.fruitQty}
                                        onChange={changeInput}/>
                            </td>
                            <td>
                                <button className="btn btn-success"
                                        onClick={addFruit}>
                                        <RiAddLine className="me-1"/>추가
                                </button>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <div className="row mt-4">
            <div className="col">
                <h3>총 합계 금액은 {formatNumberWithCommas(total)} 원 입니다</h3>
            </div>
        </div>
    </>);
};

export default FruitCart;