import { useParams } from "react-router";
import Jumbotron from "../Jumbotron";
import { useRecoilValue } from "recoil";
import { loginState, memberLoadingState } from "../../utils/recoil";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

const PayTest02Success = ()=>{
    //partnerOrderId 수신
    const {partnerOrderId} = useParams();

    //로그인 관련 상태
    const login = useRecoilValue(loginState);
    const memberLoading = useRecoilValue(memberLoadingState);

    //결제 승인 상태
    const [result, setResult] = useState(null);//null : 시작전, true : 성공 , false : 실패

    //effect
    useEffect(()=>{
        if(login === true && memberLoading === true) {
            sendApproveRequest();
        }
    }, [login, memberLoading]);

    const [bookList, setBookList] = useState([]);

    //callback
    const sendApproveRequest = useCallback(async ()=>{
        try {
            const resp = await axios.post(
                "/book/approve",
                {
                    partnerOrderId : partnerOrderId , 
                    pgToken : new URLSearchParams(window.location.search).get("pg_token"),
                    tid: window.sessionStorage.getItem("tid"),
                    bookList: JSON.parse(window.sessionStorage.getItem("checkedBookList"))
                }
            );
            setBookList(JSON.parse(window.sessionStorage.getItem("checkedBookList")));
            setResult(true);//성공
        }
        catch(e){
            setResult(false);//실패
        }
        finally {
            window.sessionStorage.removeItem("tid");
            window.sessionStorage.removeItem("checkedBookList");
        }
    }, [login, memberLoading]);

    const total = useMemo(()=>{
        return bookList.reduce((b, c)=>b + (c.bookPrice*c.qty) , 0);
    }, [bookList]);

    if(result === null) {
        return (<>
            <h1>결제 진행중입니다...</h1>
        </>);
    }
    else if(result === true) {
        return (<>
            <Jumbotron title="도서 구매 완료"/>

            <div className="row mt-4">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>도서명</th>
                                <th>판매가</th>
                                <th>구매수량</th>
                                <th>합계</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookList.map(book=>(
                            <tr key={book.bookId}>
                                <td>{book.bookTitle}</td>
                                <td>{book.bookPrice}원</td>
                                <td>{book.qty}권</td>
                                <td>{book.bookPrice * book.qty}원</td>
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3}>총 결제 금액</th>
                                <th>{total}원</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>);
    }
    else {
        return (<>
            <h1>결제 승인 실패...</h1>
        </>)
    }
};

export default PayTest02Success;