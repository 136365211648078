import {Routes, Route} from "react-router";
import Exam01 from './Exam01';
import Exam02 from './Exam02';
import Home from './Home';
import Todolist from "./Todolist";
import FruitCart from "./FruitCart";
import BankAccount from "./BackAccount";
import Poketmon from "./Poketmon";
import Emp from "./Emp";
import Book from "./Book";
import BookList from "./book/BookList";
import BookAdd from "./book/BookAdd";
import BookDetail from "./book/BookDetail";
import BookEdit from "./book/BookEdit";
import PageNotFound from "./error/PageNotFound";
import AutoComplete from "./search/AutoComplete";
import AutoComplete2 from "./search/AutoComplete2";
import MemberComplexSearch from "./search/MemberComplexSearch";
import Search from "./search/Search";
import MemberLogin from "./member/MemberLogin";
import DataExam01 from "./data/DataExam01";
import { useRecoilValue } from "recoil";
import { loginState } from "../utils/recoil";
import PrivateRoute from "./router/PrivateRoute";
import MyPage from "./member/MyPage";
import PayTest01 from "./pay/PayTest01";
import PayTest01Success from "./pay/PayTest01Success";
import PayTest02 from "./pay/PayTest02";
import PayTest02Success from "./pay/PayTest02Success";
import PayTest02Fail from "./pay/PayTest02Fail";
import PayTest02Cancel from "./pay/PayTest02Cancel";
import PayTest02List from "./pay/PayTest02List";
import PayTest02List2 from "./pay/PayTest02List2";
import PayTest02Detail from "./pay/PayTest02Detail";

const MainContent = ()=>{
    //recoil에서 login 상태를 불러온다
    const login = useRecoilValue(loginState);

    return (<>
        {/* 컨테이너 */}
        <div className="container-fluid">

            {/* 메인 폭 조절 영역 */}
            <div className="row my-5 pt-4">
                <div className="col-sm-10 offset-sm-1">
                    
                    {/* 주소에 따라 배치될 화면에 대한 설정(라우터) */}
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/ex01" element={<Exam01/>}/>
                        <Route path="/ex02" element={<Exam02/>}/>
                        <Route path="/todolist" element={<Todolist/>}/>
                        <Route path="/fruit-cart" element={<FruitCart/>}/>
                        <Route path="/bank-acc" element={<BankAccount/>}/>
                        <Route path="/poketmon" element={<Poketmon/>}/>

                        {/* 로그인이 필요한 페이지라면 element에 PrivateRoute를 적어서 대상을 명시하면 된다 */}
                        <Route path="/emp" element={<PrivateRoute element={<Emp/>}/>}/>
                        <Route path="/book/spa" element={<PrivateRoute element={<Book/>}/>}/>
                        <Route path="/book/list" element={<PrivateRoute element={<BookList/>}/>}/>
                        <Route path="/book/add" element={<PrivateRoute element={<BookAdd/>}/>}/>
                        {/* 경로변수를 사용할 경우 콜론과 이름을 합쳐 변수명으로 지정 */}
                        <Route path="/book/detail/:bookId" element={<PrivateRoute element={<BookDetail/>}/>}/>
                        <Route path="/book/edit/:bookId" element={<PrivateRoute element={<BookEdit/>}/>}/>

                        <Route path="/member/mypage" element={<PrivateRoute element={<MyPage/>}/>}/>
                        
                        {/* 기존 : 일반 라우팅 */}
                        {/* <Route path="/search/autocomplete" element={<AutoComplete/>}/> */}
                        {/* <Route path="/search/autocomplete2" element={<AutoComplete2/>}/> */}
                        {/* <Route path="/search/member" element={<MemberComplexSearch/>}/> */}

                        {/* 변경 : 중첩 라우팅 */}
                        <Route path="/search/*" element={<Search/>}/>

                        {/* 회원 로그인 */}
                        <Route path="/member/login" element={<MemberLogin/>}/>

                        <Route path="/data/exam01" element={<DataExam01/>}/>

                        {/* 결제 */}
                        <Route path="/paytest01" element={<PrivateRoute element={<PayTest01/>}/>}/>
                        <Route path="/paytest01/success/:partnerOrderId" element={<PrivateRoute element={<PayTest01Success/>}/>}/>
                        <Route path="/paytest02" element={<PrivateRoute element={<PayTest02/>}/>}/>
                        <Route path="/paytest02/success/:partnerOrderId" element={<PrivateRoute element={<PayTest02Success/>}/>}/>
                        <Route path="/paytest02/cancel" element={<PrivateRoute element={<PayTest02Cancel/>}/>}/>
                        <Route path="/paytest02/fail" element={<PrivateRoute element={<PayTest02Fail/>}/>}/>
                        <Route path="/paytest02/list" element={<PrivateRoute element={<PayTest02List/>}/>}/>
                        <Route path="/paytest02/list2" element={<PrivateRoute element={<PayTest02List2/>}/>}/>
                        <Route path="/paytest02/detail/:paymentNo" element={<PrivateRoute element={<PayTest02Detail/>}/>}/>

                        {/* 나머지 경로(*) 패턴을 지정해서 미 지정된 페이지를 모두 연결할 수 있다 */}
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>

                </div>
            </div>
        </div>
    </>);
};

export default MainContent;