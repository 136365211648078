import Jumbotron from "./Jumbotron";
import { useState } from 'react';
import { useCallback } from 'react';

const Todolist = ()=>{
    //state
    const [input, setInput] = useState("");
    const [data, setData] = useState([]);

    //callback
    const addData = useCallback(()=>{
        //미입력 상황을 배제
        if(input.length === 0) return;

        //setData(data.concat(input));
        setData([...data, input]);

        setInput("");
    }, [input, data]);

    //데이터 삭제 - filter를 쓰며 반드시 값 또는 인덱스를 알아야 함
    const removeData = useCallback((index)=>{
        setData(data.filter((todo, idx)=>{
            return idx !== index;//언급된 순서(index)가 아닌 것들만 추려라!
        }));
    }, [data]);

    //view
    return (<>
        <Jumbotron title="오늘의 할일"/>

        <div className="row mt-4">
            <div className="col">
                <div className="input-group">
                    <input type="text" className="form-control"
                        value={input} onChange={e=>setInput(e.target.value)}/>
                    <button className="btn btn-primary"
                        onClick={addData}>등록</button>
                </div>
            </div>
        </div>

        <hr/>

        {/* 목록 출력 */}
        {data.map((todo, index)=>(
        <div className="row mt-2" key={index}>
            <div className="col">
                <h3 onClick={e=>removeData(index)}>{todo}</h3>
            </div>
        </div>
        ))}
    </>);
};

export default Todolist;