import { useParams } from "react-router";
import Jumbotron from "../Jumbotron";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { loginState, memberLoadingState } from "../../utils/recoil";
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

const PayTest01Success = ()=>{
    //[경로변수] 주소에 있는 partnerOrderId를 읽는다 (ex : /success/xxxx)
    const {partnerOrderId} = useParams();

    //[쿼리파라미터] 주소에 있는 pg_token을 읽는다 (ex : ?pg_token=xxxx)
    //const [searchParams] = useSearchParams();//안됨

    const login = useRecoilValue(loginState);
    const memberLoading = useRecoilValue(memberLoadingState);

    //로딩 상태
    //const [loading, setLoading] = useState(false);//단순 로딩만
    const [result, setResult] = useState(null);//로딩 + 결과

    //페이지가 로딩되자마자 정보들을 모아서 승인요청을 전송 (타이밍 안맞음)
    useEffect(()=>{
        //console.log("login", login, "memberLoading", memberLoading);
        if(login === true && memberLoading === true) {
            sendApproveRequest();
        }
    }, [login, memberLoading]);

    //callback
    const sendApproveRequest = useCallback(async ()=>{
        try {
            const resp = await axios.post("/kakaopay/approve", {
                partnerOrderId: partnerOrderId ,
                pgToken : new URLSearchParams(window.location.search).get("pg_token") ,
                tid : window.sessionStorage.getItem("tid")
            });
            setResult(true);//성공
        }
        catch(e){
            setResult(false);//실패
        }
    }, [login, memberLoading]);


    if(result === null) {//진행중
        return (<>
            <h1>결제가 진행중입니다</h1>
            <ClimbingBoxLoader/>
        </>)
    }
    else if(result === true){
        return (<>
            <Jumbotron title="결제 성공 페이지"/>
    
            <h1>partnerOrderId : {partnerOrderId}</h1>
            <h1>pg_token : {new URLSearchParams(window.location.search).get("pg_token")}</h1>
            <h1>tid : {window.sessionStorage.getItem("tid")}</h1> 

            <h1>결제가 성공하였습니다!</h1>
        </>);
    }
    else {
        return (<>
            <h1>이미 완료되었거나 실패한 결제입니다</h1>
        </>);
    }
    
};

export default PayTest01Success;