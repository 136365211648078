import Jumbotron from "../Jumbotron";
import { useState } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import * as hangul from 'hangul-js';
import { useEffect } from 'react';
import axios from "axios";

const AutoComplete = ()=>{
    //state
    const [poketmonList, setPoketmonList] = useState([
        //{poketmonNo:1 , poketmonName: "파이리", poketmonType: "불꽃"},
        //{poketmonNo:2 , poketmonName: "꼬부기", poketmonType: "물"},
        //{poketmonNo:3 , poketmonName: "피카츄", poketmonType: "전기"},
    ]);
    const [keyword, setKeyword] = useState("");
    const [open, setOpen] = useState(false);

    //effect
    useEffect(()=>{
        loadPoketmonList();
    }, []);

    //callback
    const loadPoketmonList = useCallback(async ()=>{
        const resp = await axios.get("/poketmon/");
        setPoketmonList(resp.data);
    }, [poketmonList]);
    const changeKeyword = useCallback(e=>{
        setKeyword(e.target.value);
        setOpen(e.target.value.length > 0);
    }, [keyword]);
    const selectKeyword = useCallback(text=>{
        setKeyword(text);
        setOpen(false);
    }, [keyword]);

    //memo
    const searchResult = useMemo(()=>{
        //키워드가 없으면 결과를 표시하지 마라
        if(keyword.length === 0) return [];

        //키워드가 있으면 이름을 비교해서 필터링해라
        return poketmonList.filter(poketmon=>{
            //if(포켓몬의 이름이 키워드를 포함하고 있으면) {
            //if(poketmon.poketmonName.indexOf(keyword) >= 0){//완성된글자 비교
            if(hangul.search(poketmon.poketmonName, keyword) >= 0) {
                return true;
            }
            return false;
        });
    }, [keyword, poketmonList]);

    //view
    return (<>
        <Jumbotron title="자동완성 만들어보기"
                content="최초 1회 불러오고 내부적으로 처리"/>

        {/* 포켓몬스터 이름 자동완성 */}
        <div className="row mt-4">
            <div className="col">
                <div className="form-group">
                    <input type="text" className="form-control" 
                            placeholder="몬스터 이름"
                            value={keyword} 
                            onChange={changeKeyword}/>
                    {open === true && (
                    <ul className="list-group">
                        {searchResult.map(poketmon=>(
                        <li key={poketmon.poketmonNo} 
                                className="list-group-item"
                                onClick={e=>selectKeyword(poketmon.poketmonName)}>
                            
                            {poketmon.poketmonName}
                            
                            <span className="text-muted ms-4">
                                {poketmon.poketmonType}
                            </span>
                        </li>
                        ))}
                    </ul>
                    )}
                </div>
            </div>
        </div>
    </>);
};

export default AutoComplete;