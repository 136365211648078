import Jumbotron from "./Jumbotron"
import { useState } from 'react';
import { useCallback } from 'react';

const Exam02 = ()=>{
    //state
    const [dataList, setDataList] = useState(
        [
            {no : 1 , name : "한국" , capital : "서울"}, 
            {no : 2 , name : "일본", capital : "도쿄"}, 
            {no : 3 , name : "스웨덴", capital : "스톡홀롬"}
        ]
    );

    const [input, setInput] = useState({
        name : "",
        capital : "",
    });
    
    //callback
    //- 입력값 설정 기능
    const changeInput = useCallback(e=>{
        setInput({
            ...input,
            [e.target.name] : e.target.value
        });
    }, [input]);

    // - 입력된 값을 목록에 추가하는 기능
    const addData = useCallback(()=>{
        //[1] 번호를 구할것 (없으면 1)
        //const no = dataList.length === 0 ? 1 : dataList[dataList.length-1].no+1;
        const no = dataList[dataList.length-1].no+1 || 1;

        //[2] dataList에 input의 내용을 추가
        // setDataList(dataList.concat({
        //     ...input, 
        //     no : no
        // }));
        setDataList([...dataList, {
            ...input, 
            no : no
        }]);

        //[3] input을 초기화
        setInput({
            name : "",
            capital : "",
        });

    }, [input, dataList]);

    // - index에 들어있는 순서의 데이터를 삭제하는 기능
    const removeData = useCallback((index)=>{
        setDataList(dataList.filter((data, idx)=>{
            return idx !== index;//index와 위치가 다른 항목만 찾아라!(= index랑 같은 위치를 삭제하라)
        }));
    }, [dataList]);

    // (참고) filter 등 신명령을 쓰기 싫으면...
    // const removeData = useCallback((index)=>{
    //     const copyDataList = [];
    //     for(let i=0; i < dataList.length; i++) {
    //         if(i !== index) {
    //             copyDataList.push(dataList[i]);
    //         }
    //     }
    //     setDataList(copyDataList);
    // }, [dataList]);

    return (<>
        <Jumbotron title="객체 배열 state의 사용법"/>

        <div className="row mt-4">
            <div className="col">
                <table className="table">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>나라</th>
                            <th>수도</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* 목록을 map으로 출력 */}
                        {dataList.map((data, index)=>(
                        <tr key={data.no}>
                            <td>{data.no}</td>
                            <td>{data.name}</td>
                            <td>{data.capital}</td>
                            <td>
                                <button className="btn btn-warning">수정</button>
                                <button className="btn btn-danger ms-2"
                                        onClick={e=>removeData(index)}>삭제</button>
                            </td>
                        </tr>
                        ))}

                        {/* 등록에 필요한 입력창을 준비 */}
                        <tr>
                            <td></td>
                            <td>
                                <input type="text" className="form-control"
                                        placeholder="나라명 입력"
                                        name="name"
                                        value={input.name}
                                        onChange={changeInput}/>
                            </td>
                            <td>
                                <input type="text" className="form-control"
                                        placeholder="수도명 입력"
                                        name="capital"
                                        value={input.capital}
                                        onChange={changeInput}/>
                            </td>
                            <td>
                                <button className="btn btn-success"
                                        //onClick={e=>addData()}
                                        onClick={addData}
                                        >추가</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>);
};

export default Exam02;