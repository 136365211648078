import { Navigate, useNavigate, useParams } from "react-router";
import Jumbotron from "../Jumbotron";
import { useEffect } from 'react';
import { useCallback } from 'react';
import axios from "axios";
import { useState } from 'react';
import { useMemo } from 'react';

const BookDetail = ()=>{
    //파라미터를 읽는 명령
    const {bookId} = useParams();

    //이동 도구
    const navigate = useNavigate();

    //state
    const [book, setBook] = useState(null);
    const [load, setLoad] = useState(false);//통신이 완료되면 true

    //effect
    //- effect에는 async를 쓸 수 없다
    useEffect(()=>{
        loadBook();
    }, []);

    //callback
    const loadBook = useCallback(async ()=>{
        try {
            //플랜 A
            const resp = await axios.get("/book/"+bookId);
            setBook(resp.data);
        }
        catch(e) {
            //플랜 B(A에서 조회가 안될 때 - 404가 반환될 때)
            setBook(null);
        }
        setLoad(true);//로딩 완료되었음을 마킹
    }, [book, bookId]);

    const deleteBook = useCallback(async ()=>{
        await axios.delete("/book/"+bookId);
        navigate("/book/list");
    }, [book, bookId]);

    //화면의 상태가 총 3가지로 존재할 수 있다.
    //1. 로딩 전
    //2. 로딩 후
    //  (1) 데이터 있음
    //  (2) 데이터 없음
    if(load === false) {//1. 로딩 완료 전이면
        return (<>
            <Jumbotron title={"?번 도서 상세정보"}/>

            <div className="row mt-4">
                <div className="col-sm-3">
                    도서제목
                </div>
                <div className="col-sm-9">
                    <span className="placeholder col-6"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    저자
                </div>
                <div className="col-sm-9">
                <span className="placeholder col-4"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    출판사
                </div>
                <div className="col-sm-9">
                <span className="placeholder col-4"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    출간일
                </div>
                <div className="col-sm-9">
                    <span className="placeholder col-3"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    판매가
                </div>
                <div className="col-sm-9">
                    <span className="placeholder col-2"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    페이지 수
                </div>
                <div className="col-sm-9">
                    <span className="placeholder col-2"></span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-3">
                    장르
                </div>
                <div className="col-sm-9">
                    <span className="placeholder col-2"></span>
                </div>
            </div>

            {/* 각종 버튼들 */}
            <div className="row mt-4">
                <div className="col text-end">
                    <button className="btn btn-success placeholder col-2">신규등록</button>
                    <button className="btn btn-secondary placeholder col-2 ms-2">목록보기</button>
                    <button className="btn btn-warning placeholder col-2 ms-2">수정하기</button>
                    <button className="btn btn-danger placeholder col-2 ms-2">삭제하기</button>
                </div>
            </div>
        </>);
    }

    if(book === null) {//2-(2). 데이터가 없으면
        return <Navigate to="/notFound"/>
    }

    //view. 2-(1)
    return (<>
        <Jumbotron title={bookId +"번 도서 상세정보"}/>

        <div className="row mt-4">
            <div className="col-sm-3">
                도서제목
            </div>
            <div className="col-sm-9">
                {book.bookTitle}
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                저자
            </div>
            <div className="col-sm-9">
                {book.bookAuthor}
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                출판사
            </div>
            <div className="col-sm-9">
                {book.bookPublisher}
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                출간일
            </div>
            <div className="col-sm-9">
                {book.bookPublicationDate}
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                판매가
            </div>
            <div className="col-sm-9">
                {book.bookPrice} 원
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                페이지 수
            </div>
            <div className="col-sm-9">
                {book.bookPageCount} p
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-3">
                장르
            </div>
            <div className="col-sm-9">
                {book.bookGenre}
            </div>
        </div>

        {/* 각종 버튼들 */}
        <div className="row mt-4">
            <div className="col text-end">
                <button className="btn btn-success"
                    onClick={e=>navigate("/book/add")}>신규등록</button>
                <button className="btn btn-secondary ms-2"
                    onClick={e=>navigate("/book/list")}>목록보기</button>
                <button className="btn btn-warning ms-2"
                    onClick={e=>navigate("/book/edit/"+bookId)}>수정하기</button>
                <button className="btn btn-danger ms-2"
                    onClick={deleteBook}>삭제하기</button>
            </div>
        </div>

    </>);
};

export default BookDetail;